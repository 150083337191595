import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [isUserAttend, setIsUserAttend] = useState(false);
  const [guestAttend, setGuestAttend] = useState(false);

  useEffect(() => {
    if (props.user.event_gate) {
      let _gate = props.user.event_gate?.find(
        (v) => v.id_gate === "1"
      );
      setGate(_gate);

      if(_gate?.is_user_attend === "1"){
        setIsUserAttend(true);
      }
    }
  }, [props?.user]);

  useEffect(() => {
    initAllowed();
  }, [gate]);

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         setLoading(true);

  //         handleCheckin();
  //       }, 10);
  //       return;
  //     } else if (checkAllow === "allowed-to-re-checkin") {
  //       setTimeout(() => {
  //         setLoading(true);

  //         handleReCheckin();
  //       }, 10);
  //       return;
  //     }
  //   }
  // }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    if(checkAllow === "allowed-to-checkin"){
      handleCheckin();
    }
    else{
      handleReCheckin();
    }

   
  };



  const handleReCheckin = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: "1",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
            if (response?.data?.error_code === "not_checkout") {
              props.onSuccess();
            }
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckin = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: "1",
      is_user_attend: isUserAttend ? '1' : '0',
      guest_attend: guestAttend ? '1' : '0'
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkin", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleReentry = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: "1",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (gate !== null) {
      if (gate?.checkin_time !== "0000-00-00 00:00:00") {
        if(props.user?.guest === "1"){
         if(gate?.guest_attend === "0"){
          setCheckAllow("allowed-to-checkin");
         }
         else{
          if(gate?.checkout_time === "0000-00-00 00:00:00"){
            setCheckAllow("already-checkin");
          }
          else{
            setCheckAllow("allowed-to-re-checkin");
          }
         }
        }
        else{
          if(gate?.checkout_time === "0000-00-00 00:00:00"){
            setCheckAllow("already-checkin");
          }
          else{
            setCheckAllow("allowed-to-re-checkin");
          }
        }
      } else {
        setCheckAllow("allowed-to-checkin");
      }
    }
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

 

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
      <div className="text-center font-bold">
          <div className="text-xl uppercase">Checkin</div>
        </div>
       
        {checkAllow === "allowed-to-checkin" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO CHECKIN
          </div>
        )}

        {checkAllow === "allowed-to-re-checkin" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO RE-ENTRY
          </div>
        )}

        {checkAllow === "already-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY CHECKIN : {getCheckinTime()}
          </div>
        )}

        {checkAllow === "not-allowed-to-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            NOT ALLOWED TO CHECKIN
          </div>
        )}

        {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
          ALREADY CHECKED IN
        </div> */}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              {gate?.photo !== "" && (
                <>
                  <div className="mb-2 flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded">
                    {checkAllow === "allowed-to-re-checkin" && (
                      <img
                        src={CONFIG.URL_MEDIA + "/" + gate?.photo}
                        className="rounded w-full h-full  bject-contain"
                        alt=""
                      />
                    )}
                  </div>
                </>
              )}

              {
                checkAllow === "allowed-to-checkin" &&   <>
                <div onClick={gate?.is_user_attend === "0" ? () => setIsUserAttend(!isUserAttend) : () => {}} className={`${!isUserAttend ? 'border-orange-700 bg-orange-500' : 'border-gray-700 bg-gray-500'} p-4 rounded-md border-b-4  min-w-[160px]`}>
                    <div className="text-center text-xl font-bold text-white capitalize mb-0">
                      {props.user?.fullname}
                    </div>
                    <div className="text-center text-white capitalize font-normal">
                      {props.user?.organization}
                    </div>
                    <div className="text-center text-white capitalize font-normal">
                      {props.user?.job_title}
                    </div>
                </div>
  
                {/* GUEST */}{
                  props.user?.guest === "1" &&   <div onClick={() => setGuestAttend(!guestAttend)} className={`${!guestAttend ? 'border-orange-700 bg-orange-500' : 'border-gray-700 bg-gray-500'} p-4 rounded-md border-b-4  mt-5 min-w-[160px]`}>
                  <div className="text-center text-xl font-bold text-white capitalize mb-0">
                    Guest
                  </div>
                </div>
                }
              
                </>
              }

{
                checkAllow === "already-checkin" &&   <>
                <div className={`border-gray-700 bg-gray-500 p-4 rounded-md border-b-4  min-w-[160px]`}>
                    <div className="text-center text-xl font-bold text-white capitalize mb-0">
                      {props.user?.fullname}
                    </div>
                    <div className="text-center text-white capitalize font-normal">
                      {props.user?.organization}
                    </div>
                    <div className="text-center text-white capitalize font-normal">
                      {props.user?.job_title}
                    </div>
                </div>
  
                {/* GUEST */}{
                  props.user?.guest === "1" &&   <div className={`'border-gray-700 bg-gray-500 p-4 rounded-md border-b-4  mt-5 min-w-[160px]`}>
                  <div className="text-center text-xl font-bold text-white capitalize mb-0">
                    Guest
                  </div>
                </div>
                }
              
                </>
              }

            {
              checkAllow === "allowed-to-re-checkin" && <div className={` rounded-md  min-w-[160px]`}>
              <div className="text-center text-xl font-bold text-black capitalize mb-0">
                {props.user?.fullname}
              </div>
              <div className="text-center text-black capitalize font-normal">
                {props.user?.organization}
              </div>
              <div className="text-center text-black capitalize font-normal">
                {props.user?.job_title}
              </div>
          </div>
            }



            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`  px-10 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  w-full text-center`}
            >
              CANCEL
            </button>

            {(
              checkAllow === "allowed-to-checkin") && (
              <button
                type="button"
                disabled={!isUserAttend || (gate?.is_user_attend === "1" && !guestAttend)}
                onClick={isUserAttend ? () => onSubmit() : () => {}}
                className={`${isUserAttend  ? (gate?.is_user_attend === "1" && !guestAttend) ? "border-gray-700 bg-gray-500" : "border-green-700 bg-green-500" : "border-gray-700 bg-gray-500"}  px-10 py-2 rounded-lg text-white font-bold border-b-4   w-full text-center`}
              >
                {loading ? "Loading..." : "CONFIRM"}
              </button>
            )}

{(checkAllow === "allowed-to-re-checkin") && (
              <button
                type="button"
                onClick={() => onSubmit()}
                className={`border-green-700 bg-green-500 px-10 py-2 rounded-lg text-white font-bold border-b-4   w-full text-center`}
              >
                {loading ? "Loading..." : "CONFIRM"}
              </button>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;
