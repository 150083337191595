const InputEmail = ({
  label,
  name,
  setValue,
  register,
  error,
  required = true,
  value
}) => {
  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged = (error && isValid) || (!error && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  return (
    <div className="flex flex-col mx-auto justify-center items-center w-full">
      <label className="text-black w-full text-left">{label}</label>
      <input
        type="email"
        autoComplete="off"
        role="presentation"
        className="border-2 px-2 py-2 rounded-lg w-full text-left"
        onInput={(e) => {
          setValue(e.target.value.trim().toLowerCase());
        }}
        {...register(name, {
          required: required,
          validate: handleEmailValidation,
        })}
      />
      {error && (
        <>
          {error?.type === "validate" ? (
            <span className="text-red-500 text-sm text-left w-full">
              Email not Valid
            </span>
          ) : (
            <span className="text-red-500 text-sm text-left  w-full">
              This field is required
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default InputEmail;
