import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import logo from "../../assets/logo-tha.png";
import InputEmail from "../../components/input/input-email";
import InputText from "../../components/input/input-text";
import InputNumber from "../../components/input/input-number";
import InputChoose from "../../components/input/input-choose";
import InputTextarea from "../../components/input/input-textarea";

const FormRegister = (props) => {
  const [loading, setLoading] = useState(false);
  const [attend, setAttend] = useState(null);
  const [guest, setGuest] = useState(null);

  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (attend !== null) {
      setMsg("");
    }
  }, [attend]);

  useEffect(() => {
    if (guest !== null) {
      setMsg("");
    }
  }, [guest]);

  const onSubmit = (data) => {
    // console.log(data);
    // props.onSuccess(data);
    // return;

    setMsg("");
    if(data.email !== data.email_confirm){
      setMsg("Email Addresses entered do not match");
      return;
    }

    if (loading) return;


    setLoading(true);
    const payload = {...data};

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/user/register", form_data, {
          headers: {
            token: props?.user?.token,
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(data);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center  px-5 py-5 bg-white bg-opacity-80 backdrop-blur-2xl rounded-md w-full md:w-1/2"
      >
        <img
          src={logo}
          className="w-28 object-contain mb-10 bg-white p-3 rounded-2xl "
          alt=""
        />

        <div className="text-center  mb-5">
          <div className="text-2xl font-bold mb-3">
            Please Submit the Following Details to Complete your Registration:
          </div>
        </div>

        <div className="mb-10 w-full space-y-2">
          <InputText
            label={"Full Name:"}
            name={"fullname"}
            register={register}
            error={errors.fullname}
          />
          <InputText
            label={"Organization:"}
            name={"organization"}
            register={register}
            error={errors.organization}
          />
          <InputText
            label={"Job Title:"}
            name={"job_title"}
            register={register}
            error={errors.job_title}
          />
          <InputNumber
            label={"Contact Number:"}
            name={"phone"}
            register={register}
            error={errors.phone}
          />
          <InputEmail
            label={"Email Address:"}
            name={"email"}
            setValue={(val) => {
              setValue("email", val);
            }}
            register={register}
            error={errors.email}
          />
            <InputEmail
            label={"Confirm Email Address:"}
            name={"email_confirm"}
            setValue={(val) => {
              setValue("email_confirm", val);
            }}
            register={register}
            error={errors.email_confirm}
          />
          <InputTextarea
            label="Do you have any questions about the THA eTender platform?"
            name={"question"}
            register={register}
            error={errors.question}
            required={false}
          />
          <InputChoose
            label={"Would you be Bringing a Guest?"}
            name={"guest"}
            register={register}
            setValue={(val) => {
              setValue("guest", val);
            }}
            value={watch("guest")}
            error={errors.guest}
            options={[
              { label: "YES", value: "1" },
              { label: "NO", value: "0" },
            ]}
          />
        </div>

        <div className="text-center font-bold text-red-500 mb-5">{msg}</div>

        <div className="mx-auto flex justify-center items-center">
          <button
            type="submit"
            className={`w-max px-10 py-2 rounded-lg text-white font-bold border-b-4 border-green-700 bg-green-500  text-center`}
          >
            {loading ? "Loading..." : "SUBMIT"}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormRegister;
