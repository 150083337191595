const InputChoose = ({
  label,
  name,
  register,
  error,
  required = true,
  options,
  setValue,
  value,
}) => {
  return (
    <div className="flex flex-col mx-auto justify-center items-center w-full">
      <label className="text-black w-full text-left">{label}</label>
      <input
        type="text"
        autoComplete="off"
        role="presentation"
        className="hidden border-2 px-2 py-2 rounded-lg w-full text-left"
        {...register(name, {
          required: required,
        })}
      />
      <div className="grid grid-cols-2 gap-2 w-full mt-1">
        {options?.map((item, key) => {
          return (
            <button
              key={key}
              type="button"
              onClick={() => setValue(item.value)}
              className={`${
                value === item.value
                  ? "border-amber-700 bg-amber-500"
                  : "border-gray-700 bg-gray-500"
              }   w-full px-10 py-2 rounded-lg text-white font-bold border-b-4 text-center`}
            >
              {item.label}
            </button>
          );
        })}
      </div>

      {error && (
        <>
          <span className="text-red-500 text-sm text-left  w-full">
            Please choose one
          </span>
        </>
      )}
    </div>
  );
};

export default InputChoose;
