import { useState } from "react";
import FormThankyou from "./thankyou.from";
import FormVerification from "./verification.from";
import FormRegister from "./register.from";

const PageRegisterEvent = (props) => {
  const [state, setState] = useState("verification");
  const [user, setUser] = useState(null);

  const handleSuccesverification = () => {
    setState("register");
  };

  const handleSuccesRegister = (data) => {
    setUser(data);
    setState("thankyou");

    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  };

  const handleResetForm = () => {
    setUser(null);
    setState("verification");
  };

  return (
    <>
      <div
        className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="flex flex-col justify-center items-center py-5 w-full">
          <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
            {/* VERIFICATION */}
            {state === "verification" && (
              <FormVerification
                onSuccess={(data) => handleSuccesverification(data)}
              />
            )}

            {/* REGISTER */}
            {state === "register" && (
              <FormRegister
                onSuccess={(data) => handleSuccesRegister(data)}
              />
            )}

            {/* THANK YOU */}
            {state === "thankyou" && (
              <FormThankyou user={user} onSuccess={() => handleResetForm()} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageRegisterEvent;
