import { useEffect } from "react";
import logo from "../../assets/logo-tha.png";

const FormThankyou = (props) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     props.onSuccess();
  //   }, 5000);
  // }, []);

  return (
    <>
      <div className="flex flex-col justify-center items-center  px-5 py-5 bg-white bg-opacity-80 backdrop-blur-2xl rounded-md w-full md:w-1/2">
        <img
          src={logo}
          className="w-28 object-contain mb-5 bg-white p-3 rounded-2xl "
          alt=""
        />

        <div className="text-center font-bold mb-5">
          <div className="text-2xl mt-5 capitalize">Hi, {props.user?.fullname}</div>
        </div>

        <div className="flex flex-col">
          <div className="text-center text-xl font-bold mb-3">
            Congratulations!
          </div>
        </div>
        <div className="flex flex-col w-full justify-center items-center text-center space-y-3">
          <div className="text-base font-normal text-center">
            Your registration for the THA Vendor Sensitization Session carded
            for 7th May 2024 at the Main Auditorium, Shaw Park Complex, has been
            submitted successfully.
          </div>
          <div className="text-base text-center font-bold">
            You will receive a confirmation email shortly with a QR code that
            must be presented to access the event.
          </div>

          <div className="text-base font-normal text-center">
            Please check your Spam/Junk folders if you do not see the email in
            your inbox.
          </div>

          <div className="text-base font-normal text-center">
            We look forward to hosting you!
          </div>

          <div className="text-base font-normal text-center">The THA Team</div>
        </div>

        {/* <div className="mx-auto flex justify-center items-center">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            OK
          </button>
        </div> */}
      </div>
    </>
  );
};

export default FormThankyou;
